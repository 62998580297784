import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import GraphQLErrorList from "../components/GraphQLErrorList";
import componentMapper from "../components/componentMapper";
import CustomComponent from "../components/CustomComponent";
import Pricing from "../components/Pricing";

export const query = graphql`
  query discountPagesQuery($sale_key: String!) {
    page: sanityPage(slug: { current: { eq: "discount" } }) {
      ...PageInfo
    }
    offer: pagesOffer(sale_key: { eq: $sale_key }) {
      ...PriceOffer
    }
    settings: sanitySiteSettings {
      title
      id
      facebook
      instagram
      twitter
      _rawOpenGraph(resolveReferences: { maxDepth: 10 })
      _rawLogo(resolveReferences: { maxDepth: 10 })
    }
    footer: sanitySiteFooter {
      _rawContent(resolveReferences: { maxDepth: 10 })
      _rawLinks(resolveReferences: { maxDepth: 10 })
      _rawSlug(resolveReferences: { maxDepth: 10 })
    }
  }
`;

const Discount = ({ data, errors, pageContext }) => {
  if (errors) {
    return (
      <GraphQLErrorList errors={errors} />
    );
  }

  const { page, offer, settings, footer } = data;
  const { _rawContent, _rawOpenGraph } = page;


  const children = _rawContent?.map((block) => {
    if (block._type === "custom") {
      if (block.widget_id === "pricing_card") {
        return <Pricing data={data.offer} type={pageContext.sale_key} />
      }
      return <CustomComponent data={block} />;
    }
    const Component = componentMapper(block._type);
    return <Component key={block._key} data={block} />;
  });

  return (
    <Layout
      title={_rawOpenGraph?.title}
      description={_rawOpenGraph?.description}
      settings={settings}
      footer={footer}
    >
      <div className="section-spacer">
        <div className="row">
          <div className="col-xs-18 col-md-16 col-md-offset-1 col-lg-14 align-justify">
            <h1 className="xxl">
              {offer.title} <br />{" "}
              <span
                className="text-blue"
                dangerouslySetInnerHTML={{ __html: offer.header2 }}
              />
            </h1>
            <div className="row">
              <div className="col-xs-18 col-md-12">
                <p className="p">{offer.description}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {children}
    </Layout>
  );
};

export default Discount;
